<template>
<div v-for="item in tuiguang" :key="item">


  <div class="header"></div>
  <div class="wrap">
    <div class="center" v-for="list in item.banner" :key="list"> 
      <img :src="list.banner1" />
      <img :src="list.banner2" />
      <img :src="list.banner3" />
      <img :src="list.banner4" />
      <img :src="list.banner5" />
      <img :src="list.banner6" />
      <img :src="list.banner7" />
      <img :src="list.banner8" />
      <img :src="list.banner9" />
      <img :src="list.banner10" />
      <img :src="list.banner11" />
      <img :src="list.banner12"  usemap="#map">
      <img :src="list.banner13" />
     
      <map id="map" name="map" v-for="list in item.tel" :key="list">
          <area shape="rect" coords= "0,0,360,600" :href="'tel:' + tel1"> 

          <area shape="rect" coords= "360,0,720,600" :href="tel2">
      </map>
      <br><br><br>
    </div>
    <!--底部悬浮-->
    <div id="footerBar" class="footerBar" v-for="list in item.dibu" :key="list">
     <table><tbody><tr>
       <td style="font-size:18px" class="green"><span class="zaax-wxh" style="color:#000">{{ list.dibu1 }}</span></td>
 
       <td style="color:red;font-size:18px"><a :href="list.dibu2"  >{{ list.dibu3}}</a></td>
      </tr></tbody></table>
    </div>

 </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad9.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 